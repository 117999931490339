* {
  padding: 0;
  margin: 0;
}

ul,
li,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

@keyframes ani-demo-spin {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.route {
  margin-top: 20px;
  display: flex;
  li {
    flex: 1;
    text-align: center;
  }
}

.app-root {
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
}

.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}
