.sider {
  width: 200px;
  height: 100vh;
}

.container {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.logo {
  width: 200px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  color: #dedede;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  box-shadow: 0 4px 4px -4px #dedede;
  border-bottom: 1px solid #dedede;
}

.user {
  font-size: 14px;
  height: 100%;
  color: #333;
  cursor: pointer;

  img {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    border: 2px solid #dedede;
    background: #fff;
  }
}

.content {
  width: 100%;
  height: 100%;
}

.noScroll {
  &::-webkit-scrollbar {
    display: none;
  }
}
